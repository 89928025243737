import { storeToRefs } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { APP_ROUTER } from '@qctsw/common'
import { usePagination } from '@/composables/usePagination'
import { useGetRouteQuery } from '@/shared/function'
import {
  checkUserFocusMpApi,
  generateMpQrcodeApi,
  getFeedbackListApi,
  getNotificationsApi,
  readNotificationApi,
} from '@/server'
import type {
  FeedbackMessageResponse,
  MessageParams,
  MessageResponse,
} from '@/server/types'
import type { OmitPaginationParams } from '@/server/request/types'

export function logicUserFeedback() {
  const page = useGetRouteQuery('page', '1')
  const list = ref<FeedbackMessageResponse[]>([])
  const pagination = usePagination({
    type: 'pageNumber',
    request: p => getFeedbackListApi({ ...p }),
  })
  const getFeedback = async (page: number | string = 1) => {
    const p = Number.isNaN(Number(page)) ? 1 : Number(page)
    const { list: data } = (await pagination.loadPaginationData(p)) || {}
    list.value = data || []
  }

  useActivatedWatch(() => [
    watch(page, () => {
      getFeedback(page.value)
      window.scrollTo(0, 0)
    }),
  ])

  return {
    page,
    list,
    pagination,
    state: computed(() => pagination.pagination.status),
    getFeedback,
  }
}

export function logicUserNotification(options: {
  isNewNotification?: boolean
  params?: OmitPaginationParams<MessageParams>
} = {}) {
  const { params = {} } = options
  if (options.isNewNotification)
    params.readStatus = 0

  const page = useGetRouteQuery('page', '1')

  const router = useRouter()
  const notifications = ref<MessageResponse[]>([])
  const pagination = usePagination({
    type: 'pageNumber',
    request: p => getNotificationsApi({ ...p, ...params }),
  })
  const getNotifications = async (page: number | string = 1) => {
    const p = Number.isNaN(Number(page)) ? 1 : Number(page)
    const { list } = (await pagination.loadPaginationData(p)) || {}
    notifications.value = list || []
  }

  const readNotification = async (id: string) => {
    try {
      const res = await readNotificationApi(id)
      if (res) {
        const index = notifications.value.findIndex(v => v.id === id)
        if (index !== -1)
          notifications.value[index].readStatus = 1
      }
    }
    catch (e) {
      useMessage.error(getErrorMsg(e))
    }
  }

  function goPage(data: MessageResponse) {
    const MAP = {
      1: `${APP_ROUTER.complaintDetail('')}[id]`,
      2: `${APP_ROUTER.complaintDetail('')}[id]`,
      3: `${APP_ROUTER.userComment()}`,
      4: `${APP_ROUTER.complaintDetail('')}[id]`,
    } as Record<string, string>
    const path = MAP[data.notifyType]
    if (path)
      router.push({ path: path.replace('[id]', data.dataId || '') })
  }

  useActivatedWatch(() => [
    watch(page, () => {
      getNotifications(page.value)
      window.scrollTo(0, 0)
    }),
  ])
  return {
    page,
    notifications,
    pagination,
    state: computed(() => pagination.pagination.status),
    getNotifications,
    readNotification,
    goPage,
  }
}

/**
 * 是否关注公众号
 */
export function useFocusMP() {
  const showDialog = ref(false)
  const qrcodeUrl = useSessionStorage('USER_WX_MP_QRCODE', '')
  const isFocusMp = useSessionStorage('USER_WX_MP_FOCUS', false)
  const { USER_INFO } = storeToRefs(useAuth())

  watch(showDialog, async () => {
    if (showDialog.value && USER_INFO.value.type !== 'GhostUserInfo') {
      try {
        const { data } = await generateMpQrcodeApi(String(USER_INFO.value.id))
        qrcodeUrl.value = data
      }
      catch (e) {
        console.error(e)
      }
    }
  })
  async function checkUserFocusMp() {
    try {
      const { data } = await checkUserFocusMpApi()
      isFocusMp.value = data
    }
    catch (e) {
      console.error(e)
    }
    if (isFocusMp.value)
      return
    showDialog.value = true
  }

  return {
    showDialog,
    qrcodeUrl,
    checkUserFocusMp,
  }
}
